import React, { ChangeEvent, useState } from 'react';

import AkamaiPlans from '../contents/akamai_plans.json';
import HetznerPlans from '../contents/hetzner_plans.json';
import AkamaiLogo from '../icons/akamai.svg';
import HetznerLogo from '../icons/hetzner.svg';
import Layout from '../components/Layout';

import TableView from '../components/TableView';
import CTA from '../components/CTA';
import { useSiteMetadata } from '../hooks';
import Container from '../components/Container';
import { HeadFC } from 'gatsby';
import Seo from '../components/Seo';

interface Plan {
  name: string;
  description: string;
  plans: { [key: string]: string }[];
}

const columns = [
  {
    key: 'label',
    name: 'Plans',
  },
  {
    key: 'nodes',
    name: 'Number of Nodes',
  },
  {
    key: 'price_monthly',
    name: 'Monthly',
  },
  {
    key: 'price_hourly',
    name: 'Hourly',
  },
  {
    key: 'memory',
    name: 'RAM',
  },
  {
    key: 'vcpus',
    name: 'CPU',
  },
  {
    key: 'disk',
    name: 'Total Storage',
  },
];

const PROVIDERS = [
  {
    label: 'Hetzner',
    key: 'hetzner',
    icon: <HetznerLogo className="h-5 w-5" />,
  },
  {
    label: 'Akamai Cloud',
    key: 'akamai',
    icon: <AkamaiLogo className="h-5 w-5" />,
  },
];

const Pricing = () => {
  const data = useSiteMetadata();
  const [selectedProvider, setSelectedProvider] = useState(PROVIDERS[0].key);
  const [plans, setPlans] = useState(HetznerPlans);

  const PLANS: { [key: string]: any } = {
    akamai: AkamaiPlans,
    hetzner: HetznerPlans,
  };

  const handleRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedProvider(e.target.value);
    setPlans(PLANS[e.target.value]);
  };

  return (
    <Layout>
      <section className="my-6 section">
        <Container>
          <div className="space-y-6">
            <h1 className="text-center">Bitnimbus.io Platform Pricing</h1>
            <h3 className="tagline text-center">
              The most affordable enterprise-grade data streaming platform
            </h3>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CTA
                type="primary"
                link="/#contact-us"
                external={false}
                label="Get in Touch"
              />
            </div>
          </div>
          <div className="mt-16">
            <h2 className="text-center font-bold">
              Find the Right Plan for You
            </h2>
            <div className="mt-4">
              <h3 className="text-foreground text-2xl">Cloud Provider</h3>
              <div className="flex items-center mt-4 space-x-6">
                {PROVIDERS.map((provider) => (
                  <div
                    className={`flex items-center ${selectedProvider === provider.key ? 'text-foreground' : ''}`}
                  >
                    <input
                      type="radio"
                      aria-checked={selectedProvider === provider.key}
                      name={provider.key}
                      value={provider.key}
                      checked={selectedProvider === provider.key}
                      className={
                        selectedProvider === provider.key
                          ? 'opacity-100'
                          : 'opacity-70'
                      }
                      onChange={handleRadio}
                    />
                    <span className="only-of-type:mx-2">{provider.icon}</span>
                    <p>{provider.label}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="space-y-16 mt-16">
              <div className="mt-6 space-y-4">
                <h3 className="text-2xl text-foreground">Small Plan</h3>
                <p>
                  The small plan offers a great starting point for small
                  organizations or hobby developers to develop products with
                  Kafka.
                </p>
                <TableView
                  columns={columns}
                  data={plans.filter((p) => p.group === 'Small')}
                />
              </div>
              <div className="mt-6 space-y-4">
                <h3 className="text-2xl text-foreground">Medium Plan</h3>
                <p>
                  The medium plan offers fault tolerance and high availability
                  for production workloads.
                </p>
                <TableView
                  columns={columns}
                  data={plans.filter((p) => p.group === 'Medium')}
                />
              </div>
              <div className="mt-6 space-y-4">
                <h3 className="text-2xl text-foreground">Large Plan</h3>
                <p>
                  The large plan offers fault tolerance and high availability
                  for enterprise grade workloads.
                </p>
                <TableView
                  columns={columns}
                  data={plans.filter((p) => p.group === 'Large')}
                />
              </div>
              <div className="flex flex-col items-center justify-center space-y-4">
                <h2 className="text-foreground">
                  Spin Up Kafka Instances In Minutes
                </h2>
                <CTA
                  label="Start Your Free Trial"
                  external={true}
                  link={data.site.siteMetadata.productLink}
                  type="primary"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const Head: HeadFC = () => (
  <>
    <Seo
      title="Pricing - Bitnimbus.io"
      canonical="https://bitnimbus.io/pricing"
      description="Discover affordable, fully managed Kafka clusters that fit your needs! Starting at $16/month with no credit card required. Sign up now and optimize your workflow!"
    />
  </>
);

export default Pricing;
